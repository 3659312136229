<template>
    <transition name="fade">
    <div class="localspinner" v-if="active" @click="clickToForceClose">

        <div class="localspinner__bg"></div>

        <svg class="localspinner__spinner" width="35px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="localspinner__path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>

    </div>
    </transition>
</template>

<script>


export default {
    name: 'LocalSpinner',
    props: {
        active: { default: 0 },
    },
    data() {
      return {
          clicked: 0,
      }
    },

    methods: {
        clickToForceClose() {

            if( this.clicked > 2) {
            this.active = 0; // Force close
            this.clicked = 0;
            } else {
            this.clicked++
            }
        }
    }
    
}
</script>

<style lang="scss">


.localspinner {
    

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(white, 0.6);
        z-index: 22;
    }

    $offset: 180;
    $duration: 1.4s;

    &__spinner {
        z-index: 23;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%,-50%);
        animation: rotator $duration linear infinite;
        transform-origin: 0% 0%;
    }

    @keyframes rotator {
    0% { transform: rotate(0deg) translate(-50%,-50%); }
    100% { transform: rotate(360deg) translate(-50%,-50%); }
    }

    &__path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
        dash $duration ease-in-out infinite, 
        colors ($duration*4) ease-in-out infinite;
    }

    @keyframes colors {
        0% { stroke: #b12ad3; }
        50% { stroke: #42f492; }
        100% { stroke: #4285F4; }
    }

    @keyframes dash {
        0% { stroke-dashoffset: $offset; }
        50% {
            stroke-dashoffset: $offset/4;
            transform:rotate(135deg);
        }
        100% {
            stroke-dashoffset: $offset;
            transform:rotate(360deg);
        }
    }

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

