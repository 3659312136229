<template>
    <div class="newuser">
        <Svgicon icon="logo" class="newuser__logo" />

        <div class="corset">

            <h1 class="newuser__introtitle">Opret gratis demo konto</h1>
            <p class="newuser__introtext">Kom i gang med en gratis prøveversion af vores opgaveoverblik og få et unikt indblik i håndværkeropgaverne fra dit lokalområde.</p>

            <Signup />

            <div class="infobox">
                <img :src="require('@/assets/img/demo_konto_tiny.png')" alt="">
                <div class="infobox__bg">

                    <div class="infobox__flex">
                        <div class="infobox__left">
                            
                        </div>
                        <div class="infobox__right">
                            <h3>Kom nemt i gang på 30 sekunder</h3>
                            <ul>
                                <li>Indtast dit firmanavn samt e-mail</li>
                                <li>Se antallet af opgaver inden for dit fag og område</li>
                                <li>Gratis adgang i 14 dage - helt uden efterfølgende opkrævning</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="sellingpoints">

                <div class="small_corset">
                    <h2 class="sellingpoints__title">Der er mange fordele ved at være en del af 3byggetilbud.dk</h2>
                    <p class="sellingpoints__text">Vi hjælper allerede mere end 1.700 håndværkerfirmaer med relevante arbejdsopgaver og projekter, der passer til deres kapacitet, fagområde og ambitioner.</p>
                </div>

                <div class="sellingpoints__columns">
                    <div class="sellingpoints__column">
                        <Svgicon icon="flere_kunder"  />
                        <p class="sellingpoints__columns__title">Flere kunder</p>
                        <p class="sellingpoints__columns__text">Du får adgang til nye potentielle kunder</p>
                    </div>
                    <div class="sellingpoints__column">
                        <Svgicon icon="procenter"  />
                        <p class="sellingpoints__columns__title">Ingen procenter</p>
                        <p class="sellingpoints__columns__text">Vi tager ikke procenter af dine vundne opgaver</p>
                    </div>
                    <div class="sellingpoints__column">
                        <Svgicon icon="screening"  />
                        <p class="sellingpoints__columns__title">Screening</p>
                        <p class="sellingpoints__columns__text">Vi verificerer alle opgaver inden de viderformidles</p>
                    </div>
                    <div class="sellingpoints__column">
                        <Svgicon icon="konkurrence"  />
                        <p class="sellingpoints__columns__title">Lille konkurrence</p>
                        <p class="sellingpoints__columns__text">Du er maks. i konkurrence med to andre håndværkere</p>
                    </div>
                </div>
                
            </div>


            <div class="videos">
                <div class="videos__bg">
                    <h1 class="videos__title">Tusindvis af tilfredse håndværkere og bygherrere</h1>
                    <div class="videos__columns">
                        <div class="videos__column">

                            <div class="videos__poster" @click="videoIsOpen = true; currentVidId = 'Yu1hyQp5nPc'">
                                <img :src="require('@/assets/img/trebbienthumb.jpg')" alt="">
                                <div class="videos__poster__play"></div>
                            </div>

                            <p class="videos__columns__author">Lars Winsløw,</p>
                            <p class="videos__columns__company">Trebbien Byg ApS</p>
                            <p class="videos__columns__quote">“At være samarbejdspartner hos 3byggetilbud.dk har været en rigtig god hjælp – især for nystartede. Det gode er at man kan designe samarbejdet efter de opgaver, man gerne vil have.”</p>
                        </div>
                        <div class="videos__column">
                            <div class="videos__poster"  @click="videoIsOpen = true; currentVidId = '9tRdOKBiihE'">
                                <img :src="require('@/assets/img/thumb2.png')" alt="">
                                <div class="videos__poster__play"></div>
                            </div>
                            <p class="videos__columns__author">Wils & Götke Tømrerfirma ApS</p>                            
                            <p class="videos__columns__company">&nbsp;</p>
                            <p class="videos__columns__quote">“30-40 procent af vores opgaver får vi via 3byggetilbud.dk – og det har givet os plads og ro til at koncentrere os om det, som vi er gode til.”</p>
                        </div>
                        <div class="videos__column">
                            <div class="videos__poster"  @click="videoIsOpen = true; currentVidId = 'MnkqaQF6xnw'">
                                <img :src="require('@/assets/img/thumb3.png')" alt="">
                                <div class="videos__poster__play"></div>
                            </div>
                            <p class="videos__columns__author">Patrick Juel,</p>
                            <p class="videos__columns__company">Andelsboligforeningen TaBa</p>
                            <p class="videos__columns__quote">“3byggetilbud.dk har været en stor hjælp til at finde solide håndværkere, som kunne hjælpe os, da vi skulle have lavet tag i vores boligforening. Vi kunne ikke have gjort det uden dem.”</p>
                        </div>
                    </div>
                </div>
            </div>

            <Signup title="Opret gratis bruger"/>


            <VideoModal channel="youtube" :videoId="currentVidId" :isOpen="videoIsOpen" @isOpen="videoIsOpen = false;"/>
            
        </div>

        <footer><b>© 3byggetilbud.dk</b> &nbsp;&nbsp;I&nbsp;&nbsp; Kontakt os på <a href="tel:+45 77 33 40 00">+45 77 33 40 00</a> eller <a href="mailto:pro@3byggetilbud.dk">pro@3byggetilbud.dk</a></footer>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import LocalSpinner from '@/components/LocalSpinner';
import VideoModal from '@/components/videoModal';
import Signup from "@/components/Signup";

export default {
    components: {LocalSpinner, Signup, VideoModal},
    data() {
        return {
            videoIsOpen: false,
            currentVidId: null,
        }
    },

    methods: {}
    
}
</script>

<style  lang="scss">

.layout_blank .call {
    
    z-index: 3!important;
    @include medium_up {
        right: -30px!important;
    }
    @include medium {
            position: absolute!important;
    }
    .phone_mobile svg path {
        fill: #79255c;

    }
}



.newuser {
    margin-top: 150px;
    @include small {
        margin-top: 100px;
    }

    &__logo {
        width: 100px;
        color: $color1;
        position: fixed;
        left: 30px;
        top: 20px;
        @include medium {
            position: absolute;
        }

        @include small {
            width: 75px;
            left: 20px;
            top: 15px;
        }
    }

    
    &__introtitle {
        text-align: center;
        font-size: 3rem;
        @include small {
            font-size: 1.8rem;
        }
    }

    &__introtext {
        text-align: center;
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 2.8rem;
        margin-top: 1rem;
        font-size: 1.1rem;
        color: $color1;
    }
}

.infobox {
    position: relative;
    padding: 6rem 120px;
    margin-bottom: 2rem;
    @include medium {
        padding: 4rem 0px;
    }
    @include small {
        margin-top: 20%;
    }

    &__bg {
        padding: 8rem 3rem;
        background: $color16;
        border-radius: $radius;
        position: relative;
        overflow: hidden;
        @include small {
            padding-top: 45%;
            padding-bottom: 3rem;
        }
        
        &:after {
            content: "Uforpligtende";
            position: absolute;
            top: 34px;
            line-height: 1;
            right: -61px;
            background: $color9;
            padding: 10px 40px;
            color: white;
            transform: rotate(45deg);
            transform-origin: top;
            text-align: center;
            font-weight: 500;
            font-size: 0.9rem;
        }
        @include mobile {
            &:after {
                display: none;
            }
        }
    }

    &__flex {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include small {
            flex-direction: column;
        }
    }

    &__left {
        width: 50%;
        padding: 0 2rem;
        @include small {
            width: 100%;
            padding: 0 1rem;
        }

        
    }

    &__right {
        width: 50%;
        @include small {
            width: 100%;
        }

    }

    img {
        position: absolute;
        width: 40%;
        top: 49%;
        left: 8%;
        transform: translate(0%, -50%);
        z-index: 1;
        @include small {
            width: 80%;
            top: 6rem;
        }
    }

    ul {
        margin-top: 1rem;
        padding-left: 12px;
    }
    li {
        list-style: disc;
        padding: 5px 0;
    }
}



.sellingpoints {
    margin-top: 2rem;
    margin-bottom: 4rem;
    &__title {
        text-align: center;
        margin-bottom: 2rem;
    }
    &__text {
        text-align: center;
        max-width: 700px;
        margin: 0 auto;
        margin-bottom: 2rem;
        margin-top: 1rem;
        font-size: 1.1rem;
        color: $color1;
    }

    &__columns {
        display: flex;
        justify-content: center;
        gap: 5%;
        text-align: center;
        @include small {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 3rem;
        }
        &__title {
            font-weight: bold;
        }
        &__text {
            margin-top: 0.5rem;
        }
    }
    &__column {
        max-width: 240px;
        .svgicon {
            margin: 0 auto;
            max-width: 160px;
            display: block;
            margin-bottom: 1.5rem;
        }
    }
}


.videos {
    position: relative;
    padding: 6rem 120px;
    margin-bottom: 2rem;
    @include medium {
        padding: 4rem 0px;
    }
    @include small {
        margin-top: 20%;
    }

    &__bg {
        padding: 5rem 5rem;
        background: white;
        border: 1px solid $color16;
        border-radius: $radius;
        position: relative;
        overflow: hidden;
        @include small {
            padding: 4rem 3rem;
        }
        @include mobile {
            padding: 3rem 1rem;
        }
    }

    &__title {
        display: block;
        text-align: center;
        margin-bottom: 4rem;

    }


    &__poster {

        position: relative;
        cursor: pointer;

        img {
            width: 100%;
            border-radius: 15px;
            margin: 0 auto;
            margin-bottom: 1rem;
            max-width: 400px;
            height: 200px;
            object-fit: cover;
        }

        &__play {
            background-image: url('~@/assets/icons/play_demo.svg');
            background-size: 40%;
            background-repeat: no-repeat;
            background-position: 60% 50%;
            background-color: white;
            width: 60px;
            height: 60px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 100px;
            z-index: 1;
            transition: 0.3s $easeInOutBack;
            
        }

        &:hover {
            .videos__poster__play {
                transform: translate(-50%, -50%) scale(1.2);
            }
        }
    }

    &__columns {
        display: flex;
        justify-content: center;
        gap: 5%;
        text-align: center;
        @include large {
            flex-direction: column;
            
            gap: 3rem;
        }
        

        &__author {
            font-weight: 500;
            color: $link;
        }
        &__company {
            font-weight: 300;
            margin: 0;
            color: $link;
        }
        &__quote {
            font-weight: 500;
            font-style: italic;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }
            
}

footer {
    margin-top: 8rem;
    width: 100%;
    text-align: center;
    background: $color1;
    padding: 20px;
    color: white;
    font-size: 0.85rem;
    font-weight: 100;
    b, a {
        font-weight: 500;
        text-decoration: none;
        color: white;
    }
}
</style>